import React, { FC, useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Autocomplete,
    TextField,
    Grid,
    Alert,
} from '@mui/material';
import { IRestaurantTables, ISelectedTable } from '@/views/OrdersTableView/types';
import { IQrRow } from '@/views/QRCode/types';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from '@/hooks/translations';
import { uniq } from 'lodash';

interface IProps {
    open: boolean;
    qrsLoading: boolean;
    qrsGroupLoading: boolean;
    qrList: IRestaurantTables[];
    qrGroupList: IQrRow[];
    selected: ISelectedTable;
    onClose: () => void;
    onSubmit: (ids: string[], qrs: string[], qrGroups: string[]) => void;
}

const QRGroupDialog: FC<IProps> = ({
    open,
    qrsLoading,
    qrsGroupLoading,
    qrList,
    qrGroupList,
    selected,
    onClose,
    onSubmit,
}) => {
    const { t } = useTranslation('common');
    const [selectedQrs, setSelectedQrs] = useState<IRestaurantTables[]>([]);
    const [selectedQrGroups, setSelectedQrGroups] = useState<IQrRow[]>([]);

    useEffect(() => {
        if (!open) {
            return;
        }

        setSelectedQrs(qrList.filter((o) => selected.qrs.includes(o.id)));
        setSelectedQrGroups(qrGroupList.filter((o) => selected.qrGroups.includes(o.id)));
    }, [selected, open, qrList, qrGroupList]);

    const handleQrChange = (event: any, value: IRestaurantTables[]) => {
        setSelectedQrs(value);
    };

    const handleChangeGroup = (event: any, value: IQrRow[]) => {
        setSelectedQrGroups(value);
    };

    const handleClose = () => {
        setSelectedQrs([]);
        setSelectedQrGroups([]);
        onClose();
    };

    const handleSubmit = () => {
        const qrIds = selectedQrs.map((o) => o.id);
        const qrGroupIds = selectedQrGroups.map((o) => o.id);
        const uniqueIds = uniq([...qrIds, ...selectedQrGroups.map((o) => o.qr_ids).flat()]);
        onSubmit(uniqueIds, qrIds, qrGroupIds);
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>{t('QR')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} gap={3}>
                        <Autocomplete
                            multiple
                            value={selectedQrs}
                            options={qrList}
                            loading={qrsLoading}
                            getOptionLabel={(option) => option.params.name || option.params.id || option.id}
                            onChange={handleQrChange}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label={t('Select Options for QR')} />
                            )}
                            renderOption={(props, option) => {
                                return (
                                    <ListItem {...props}>
                                        <ListItemText
                                            primary={
                                                option.params.name
                                                    ? t('Table: {{name}}', {
                                                          name: option.params.name,
                                                      })
                                                    : undefined
                                            }
                                            secondary={t('ID: {{id}}', {
                                                id: option.params.id,
                                            })}
                                        />
                                    </ListItem>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Alert severity="info">{t('This feature is not supporting single QR restaurant')}</Alert>
                    </Grid>
                    {qrGroupList.length > 0 && (
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                value={selectedQrGroups}
                                options={qrGroupList}
                                loading={qrsGroupLoading}
                                getOptionLabel={(option) => option.name}
                                onChange={handleChangeGroup}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label={t('Select Options for QR Group')}
                                    />
                                )}
                                renderOption={(props, option) => (
                                    <ListItem {...props}>
                                        <ListItemText
                                            primary={option.name}
                                            secondary={t('{{count}} items', { count: option.qr_list.length })}
                                        />
                                    </ListItem>
                                )}
                            />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t('Cancel')}
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    {t('Submit')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default QRGroupDialog;
