export function updateQueryStringParameter(uri: string, key: string, value: string) {
    const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) {
        return uri.replace(re, `$1${key}=${value}$2`);
    }
    return `${uri}${separator}${key}=${value}`;
}

export function getUrlParam(str: string, key: string) {
    const parts = str.split('?');
    const d = parts?.[1]?.split('&');
    if (!d || !d[1]) {
        return null;
    }
    const map: { [key: string]: string } = {};
    d.forEach((item) => {
        const [k, v] = item.split('=');
        if (k && v !== undefined) {
            map[k] = v;
        }
    });
    return map[key];
}

export function changeDomain(url: string | undefined, newDomain: string) {
    if (!url) {
        return url;
    }

    try {
        // Create a URL object
        const urlObject = new URL(url);

        // Change the domain
        urlObject.hostname = newDomain;

        // Reconstruct and return the modified URL
        return urlObject.href;
    } catch (e) {
        console.warn(e);
        return url;
    }
}
