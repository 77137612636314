import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from '@/hooks/translations';
import {
    IconButton,
    Typography,
    SwipeableDrawer,
    Stack,
    Button,
    Box,
    useMediaQuery,
    useTheme,
    TextField,
} from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import MuiPhoneNumber from 'material-ui-phone-number';
import { onPushEvent } from '@/services/lib/gtm';
import { useWhatsApp } from '@/contexts/whatsapp';
import { whatsAppPaymentLink, parseMultiLocaleMessage } from '@/services/utils/whatsapp';
import { IOrderActionsProps, IWhatsAppFormProps, IFormState } from './types';
import styles from './index.module.scss';

const WhatsAppForm = React.memo(
    ({ formState, onFieldChange, variant, defaultCountry, countryCode }: IWhatsAppFormProps) => {
        const { t } = useTranslation('common');

        const handlePhoneChange = useCallback(
            (value: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                onFieldChange('phone', typeof value === 'string' ? value : value.target.value);
            },
            [onFieldChange],
        );

        const handleTextChange = useCallback(
            (field: keyof IFormState) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                onFieldChange(field, e.target.value);
            },
            [onFieldChange],
        );

        return (
            <Stack gap="16px">
                <MuiPhoneNumber
                    className={styles.phoneInput}
                    size="small"
                    id="phone-field"
                    fullWidth
                    defaultCountry={countryCode || defaultCountry}
                    label={t('Customer Phone')}
                    variant="outlined"
                    value={formState.phone}
                    onChange={handlePhoneChange}
                    required
                />

                {variant === 'qsrOrder' && (
                    <>
                        <TextField
                            size="small"
                            fullWidth
                            label={t('Customer name')}
                            value={formState.name}
                            onChange={handleTextChange('name')}
                        />
                        <TextField
                            size="small"
                            fullWidth
                            label={t('Order ID')}
                            value={formState.orderId}
                            onChange={handleTextChange('orderId')}
                        />
                        <TextField
                            size="small"
                            fullWidth
                            multiline
                            rows={4}
                            label={t('Default message')}
                            value={formState.message}
                            onChange={handleTextChange('message')}
                        />
                    </>
                )}
            </Stack>
        );
    },
);

const WhatsAppShareDrawer = ({
    open = false,
    drawerVisibilityToggle,
    data,
    variant = 'paymentLink',
    title = 'Whatsapp payment link',
    onSubmit,
    defaultPhone = '',
    defaultName = '',
    defaultMsg = '',
    defaultCountry = '',
    defaultOrderId = '',
    lang,
}: IOrderActionsProps) => {
    const { t } = useTranslation('common');
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {
        defaultPhone: contextPhone,
        defaultName: contextName,
        defaultMsg: contextMsg,
        defaultOrderId: contextOrderId,
    } = useWhatsApp();

    const [formState, setFormState] = useState<IFormState>({
        phone: contextPhone || defaultPhone,
        name: contextName || defaultName,
        orderId: contextOrderId || defaultOrderId,
        message: parseMultiLocaleMessage(contextMsg || defaultMsg, lang),
    });

    const handleFieldChange = useCallback((field: keyof IFormState, value: string) => {
        setFormState((prev) => ({ ...prev, [field]: value }));
    }, []);

    const handleOnClose = useCallback(() => {
        drawerVisibilityToggle(false);
        setFormState({
            phone: '',
            name: '',
            orderId: '',
            message: '',
        });
    }, [drawerVisibilityToggle]);

    const handleSend = useCallback(() => {
        if (variant === 'qsrOrder') {
            onSubmit?.(formState);
        } else if (data) {
            whatsAppPaymentLink(formState.phone, data.url);
            onPushEvent('user_share_payment_link_via_whatsapp');
        }
        handleOnClose();
    }, [variant, formState, data, onSubmit, handleOnClose]);

    useEffect(() => {
        if (data?.userInfo?.phone) {
            handleFieldChange('phone', data.userInfo.phone);
        }
    }, [data, handleFieldChange]);

    useEffect(() => {
        const contextFields = {
            phone: contextPhone,
            name: contextName,
            message: contextMsg ? parseMultiLocaleMessage(contextMsg, lang) : null,
            orderId: contextOrderId,
        };

        Object.entries(contextFields).forEach(([key, value]) => {
            if (value) handleFieldChange(key as keyof IFormState, value);
        });
    }, [contextPhone, contextName, contextMsg, lang, contextOrderId, handleFieldChange]);

    return (
        <SwipeableDrawer
            PaperProps={{
                className: styles.drawer,
                sx: {
                    ...(mobile
                        ? { borderRadius: '32px 32px 0px 0px', height: 'calc(100% - 8px)' }
                        : { maxWidth: '375px', width: '100%' }),
                    zIndex: 9999,
                },
            }}
            sx={{
                zIndex: 9999,
                '& .MuiBackdrop-root': {
                    zIndex: 9998,
                },
            }}
            anchor={mobile ? 'bottom' : 'right'}
            onOpen={() => drawerVisibilityToggle(true)}
            open={open}
            onClose={handleOnClose}
        >
            <Stack gap="8px" width="100%" padding="16px 24px" borderBottom="1px solid #EBECF2">
                <Stack gap="2" direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" fontWeight="500">
                        {t(title)}
                    </Typography>
                    <IconButton className={styles.closeButton} onClick={handleOnClose}>
                        <CloseRounded />
                    </IconButton>
                </Stack>
                {variant === 'qsrOrder' && (
                    <Typography variant="body2" color="info.main">
                        {t('You will be redirected to WhatsApp to send a message to the customer.')}
                    </Typography>
                )}
            </Stack>
            <Box flexGrow={1} padding="20px 24px" width="100%">
                <WhatsAppForm
                    formState={formState}
                    onFieldChange={handleFieldChange}
                    variant={variant}
                    defaultCountry={defaultCountry}
                    countryCode={data?.countryCode}
                />
            </Box>
            <Box width="100%" padding="16px 18px 24px" borderTop="1px solid #EBECF2">
                <Button className={styles.actionButton} variant="contained" onClick={handleSend} fullWidth>
                    {variant === 'qsrOrder' ? t('Start Chat') : t('Submit')}
                </Button>
            </Box>
        </SwipeableDrawer>
    );
};

export default WhatsAppShareDrawer;
