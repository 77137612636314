import { ReactNode, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';

import { useRestaurantContext } from '@/contexts/restaurant';
import { useUserContext } from '@/contexts/user';
import { SocketConnector } from '@/contexts/socket';
import { userVendorAssociationChanged } from '@/contexts/event.const';
import { useNavbarContext } from '@/contexts/navbar';
import { restrictRoles } from '@/constants/roleRestrictions';
import { accessTokenKey, chosenRestaurantIdKey } from '@/config/axios';
import EventBus from '@/config/event-handler';

import Navbar from '@/components/NavBar/Navbar';
import Footer from '@/components/Footer';
import { setDateLocale } from '@/common/utility';
import NotificationActionCenter from '@/components/NotificationActionCenter';
import ZenDeskWidget from '@/components/ZenDeskWidget';
import PosPrintCenter from '@/components/PosPrinteCenter';
import Sidebar from '@/components/Sidebar/index';
import WhatsappChatButton from '@/components/WhatsappChatButton';
import BrandVerificationBanner from '@/components/BrandVerificationBanner';
import RecaptchaModal from '../RecaptchaModal';
import { AgentConnectionStatusAlert } from '../AgentConnectionStatusAlert';

type LayoutProps = {
    children?: ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
    const { setUser, user } = useUserContext();
    const { showAgentConnectionAlert, setShowAgentConnectionAlert } = useNavbarContext();
    const { restaurant } = useRestaurantContext();

    const showBrandBannerVerification =
        restaurant?.brand &&
        restaurant?.config?.enableBrandEmailVerificationBanner &&
        user &&
        restrictRoles.BRAND_VERIFICATION_BANNER.includes(user.userData?.role || '') &&
        !restaurant.brand?.verified &&
        !restaurant.brand_verification_sent;

    const router = useRouter();
    const { lang } = router.query as { lang?: string };
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        if (lang) {
            setDateLocale(lang);
        }
    }, [lang]);

    useEffect(() => {
        if (user && !user.restaurantId) {
            setUser(null);
            return;
        }
        const accessToken = localStorage.getItem(accessTokenKey);
        const restaurantId = localStorage.getItem(chosenRestaurantIdKey);
        if (accessToken && restaurantId && (user?.userData?.role || '').indexOf('Vendor') > -1) {
            SocketConnector.getInstance()
                .connect({
                    accessToken,
                    restaurantId,
                })
                .catch((e) => {
                    console.log('Error while connecting with socket', e);
                });
        }
    }, [user]);

    useEffect(() => {
        EventBus.on(userVendorAssociationChanged, () => {
            setUser(null);
        });
        EventBus.on('close_menu', () => {
            setSidebarOpen(false);
        });
        EventBus.on('open_menu', () => {
            setSidebarOpen(true);
        });

        return () => {
            EventBus.remove(userVendorAssociationChanged);
            EventBus.remove('close_menu');
            EventBus.remove('open_menu');
        };
    }, []);

    const [compact, setCompact] = useState(true);

    const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up(1440), {
        defaultMatches: true,
        noSsr: false,
    });

    const isTablet = useMediaQuery((theme: any) => theme.breakpoints.between(800, 1440), {
        defaultMatches: true,
        noSsr: false,
    });

    const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down(800), {
        defaultMatches: true,
        noSsr: false,
    });

    const width = (compact || isTablet) && !isMobile ? 80 : 274;

    return (
        <Box style={{ height: '100vh', width: '100vw', display: 'flex' }}>
            <NotificationActionCenter />
            <PosPrintCenter />
            {user && (
                <Sidebar
                    compact={compact}
                    isTablet={isTablet}
                    lgUp={lgUp}
                    onClose={() => setSidebarOpen(false)}
                    open={isSidebarOpen}
                    setCompact={setCompact}
                    width={width}
                    isMobile={isMobile}
                />
            )}

            <Box
                sx={{
                    height: '100%',
                    width: user ? `calc(100vw - ${isMobile ? 0 : width}px)` : '100vw',
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: user ? `${isMobile ? 0 : width}px` : '0px',
                    transition: '0.3s ease-in-out',
                }}
            >
                {showBrandBannerVerification && <BrandVerificationBanner brandId={restaurant?.brand.id} />}
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    {user && <Navbar />}
                </Box>
                {showAgentConnectionAlert && (
                    <AgentConnectionStatusAlert onClose={() => setShowAgentConnectionAlert(false)} />
                )}
                <Box
                    sx={{
                        width: '100%',
                        padding: '1rem',
                        flex: '1 1',
                        overflowY: 'auto',
                    }}
                >
                    {children}
                </Box>
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <Footer isAuth={!!user} />
                </Box>
            </Box>
            <ZenDeskWidget />
            <WhatsappChatButton />
            <RecaptchaModal />
        </Box>
    );
};

export default Layout;
