import React, { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from '@/hooks/translations';
import { styled } from '@mui/material/styles';
import { Logout } from '@mui/icons-material';

import LanguageSelector from './LanguageSelector';
import { RestaurantSelection } from '../RestaurantSelection';
import Avatar from './Avatar';

const AvatarInfo = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const userRoleMapping: { [key: string]: string } = {
    QlubAdmin: 'Qlub_ Team',
    QlubAnalyst: 'Qlub_ Team',
    QlubCountryAnalyst: 'Qlub_ Team',
    QlubMonitor: 'Qlub_ Team',
    QlubCountryMonitor: 'Qlub_ Team',
    VendorAdmin: 'Restaurant Admin',
    VendorAnalyst: 'Restaurant Cashier',
    BrandAdmin: 'Brand Admin',
    BrandAnalyst: 'Brand Cashier',
};

export default function AccountMenu({ user, restaurant, logout }: any) {
    const { t } = useTranslation('common');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getRoleName = () => {
        if (user?.userData?.role) {
            return userRoleMapping[(user?.userData?.role || '') as string] || '';
        }
        return null;
    };

    const switchLanguageByFlag = restaurant?.config?.switchLanguageByFlag || false;
    const profileAvatar = <Avatar firstLetter={user?.userData?.name?.[0] || ''} restaurant={restaurant} />;

    return (
        <>
            <IconButton id="menu-button" onClick={handleClick}>
                {profileAvatar}
            </IconButton>
            <Menu id="menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
                <Box sx={{ padding: '1rem', minWidth: '300px' }}>
                    <Typography sx={{ color: 'primary.main' }} variant="caption">
                        {t('ACCOUNT')}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingTop: '10px',
                        }}
                    >
                        {profileAvatar}
                        <AvatarInfo sx={{ marginInlineStart: 1 }}>
                            <Typography variant="subtitle1">{user?.userData?.name}</Typography>
                            <Typography sx={{ marginTop: '-0.3rem' }} color="text.secondary" variant="caption">
                                {getRoleName()}
                            </Typography>
                        </AvatarInfo>
                    </Box>
                </Box>

                <Divider />
                <Box sx={{ padding: '1rem' }}>
                    <Typography sx={{ color: 'primary.main' }} variant="caption">
                        {t('RESTAURANT')}
                    </Typography>
                    <MenuItem sx={{ paddingX: '0px' }} onKeyDown={(e) => e.stopPropagation()}>
                        <RestaurantSelection />
                    </MenuItem>
                </Box>
                <Divider />
                {!switchLanguageByFlag && (
                    <>
                        <Box sx={{ paddingX: '1rem', paddingY: '10px' }}>
                            <Typography sx={{ color: 'primary.main' }} variant="caption">
                                {t('LANGUAGE')}
                            </Typography>
                        </Box>
                        <MenuItem>
                            <LanguageSelector />
                        </MenuItem>
                        <Divider />
                    </>
                )}
                <MenuItem
                    onClick={() => {
                        handleClose();
                        logout();
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flex: 1,
                        }}
                    >
                        <Typography variant="caption">{t('LOG OUT')}</Typography>
                        <Logout />
                    </Box>
                </MenuItem>
            </Menu>
        </>
    );
}
