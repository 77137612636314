import { IQsrOrder } from '@/views/QsrOrders/types';
import { useMemo, useCallback } from 'react';
import { isEqual, pick } from 'lodash';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useTranslation } from '@/hooks/translations';
import CopyToClipboard from '@/common/CopyToClipboard';
import classNames from 'classnames';
import { IRestaurant } from '@/views/OrdersTableView/types';
import { MultiLocale, parseJSON } from '@/components/TicketModal/utils';
import { useWhatsApp } from '@/contexts/whatsapp';

import styles from './index.module.scss';

export const USER_INFO_FIELDS = {
    NAME: 'name',
    EMAIL: 'email',
    PHONE: 'phone',
    ADDRESS: 'address',
    CAR_PLATE: 'carPlate',
    CPF: 'cpf',
} as const;

type IUserInfoFieldType = (typeof USER_INFO_FIELDS)[keyof typeof USER_INFO_FIELDS];

interface IProps {
    order: IQsrOrder;
    restaurant: IRestaurant | null | undefined;
    lang?: string;
    normalMargin?: boolean;
}

interface IFieldMap {
    [key: string]: string;
}

export default function QsrUserInfo({ order, restaurant, lang, normalMargin }: IProps) {
    const { t } = useTranslation('common');
    const { openWhatsAppDrawer } = useWhatsApp();
    const isWpEnabled = restaurant?.order_config?.enableWhatsappMsg;
    const defaultMsg = restaurant?.order_config?.defaultWhatsappMsg;

    const [fields, fieldMap] = useMemo<[string[], IFieldMap]>(() => {
        if (!restaurant) {
            return [[], {}];
        }

        const f = restaurant.order_config?.vendorQsrCardViewUserInfoField || [];
        if (!restaurant.order_config?.customerExtraFields) {
            return [f, {}];
        }

        const cef = parseJSON(restaurant.order_config?.customerExtraFields) as any[];
        const fm = cef?.reduce<IFieldMap>((acc, { key, value }) => {
            acc[key] = value as string;
            return acc;
        }, {});
        return [f, fm];
    }, [restaurant]);

    const info = useMemo<{ [key: string]: string }>(() => {
        const { meta, ...rest } = order.userInfo || {};
        return pick({ ...rest, ...meta }, fields);
    }, [order, fields]);

    const getLabel = useCallback(
        (fieldName: string) => {
            switch (fieldName as IUserInfoFieldType) {
                case USER_INFO_FIELDS.NAME:
                    return t('Name');
                case USER_INFO_FIELDS.EMAIL:
                    return t('Email');
                case USER_INFO_FIELDS.PHONE:
                    return t('Phone');
                case USER_INFO_FIELDS.ADDRESS:
                    return t('Shipping Address');
                case USER_INFO_FIELDS.CAR_PLATE:
                    return t('Car Plate');
                case USER_INFO_FIELDS.CPF:
                    return t('CPF');
                default:
                    if (fieldMap.hasOwnProperty(fieldName)) {
                        return <MultiLocale value={fieldMap[fieldName]} lang={lang} />;
                    }
                    return '';
            }
        },
        [t, fieldMap, lang],
    );

    const renderWhatsAppField = useCallback(
        (field: string, name: string | undefined) => (
            <div className={styles.phoneField}>
                {field}
                <WhatsAppIcon
                    onClick={() => openWhatsAppDrawer(field, name, defaultMsg, order?.refId)}
                    sx={{ fontSize: 22, color: '#25d366', cursor: 'pointer' }}
                />
            </div>
        ),
        [defaultMsg, openWhatsAppDrawer, order?.refId],
    );

    const getFieldContent = useCallback(
        (fieldName: string) => {
            const field = info[fieldName];

            if (!field) {
                return null;
            }

            const isPhoneField = fieldName === USER_INFO_FIELDS.PHONE;
            const content =
                isPhoneField && isWpEnabled ? renderWhatsAppField(field, info[USER_INFO_FIELDS.NAME]) : field;

            return (
                <div key={fieldName} className={styles.field}>
                    <CopyToClipboard value={field} />
                    <div className={styles.label}>{getLabel(fieldName)}</div>
                    <div className={styles.value}>{content}</div>
                </div>
            );
        },
        [info, isWpEnabled, getLabel, renderWhatsAppField],
    );

    if (isEqual(info, {}) || !fields.length) {
        return null;
    }

    return (
        <div className={classNames(styles.main, { [styles.fit]: !normalMargin })}>{fields.map(getFieldContent)}</div>
    );
}
