import { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import { Box, Menu, Typography } from '@mui/material';
import { useTranslation } from '@/hooks/translations';
import { DownloadIcon, QrCodeReadIcon } from '@/icons/svgIcons';
import QRShow from './QRShow';

export default function QbaDownloadModal() {
    const { t } = useTranslation('common');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                onClick={handleClick}
                sx={{
                    backgroundColor: '#7D00D414',
                }}
            >
                <DownloadIcon />
            </IconButton>
            <Menu id="menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
                <Box
                    sx={{
                        padding: '1rem',
                        minWidth: '300px',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '198px' }}>
                        <QrCodeReadIcon />
                        <Typography sx={{ fontWeight: '700', fontSize: '18px', paddingTop: '8px' }}>
                            {t('Scan QR Code')}
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: '500',
                                fontSize: '12px',
                                lineHeight: '20px',
                                letterSpacing: '0.14px',
                                paddingTop: '8px',
                                paddingBottom: '28px',
                                textAlign: 'center',
                                color: '#616475',
                            }}
                        >
                            {t('Scan this QR code to download Business App')}
                        </Typography>
                    </Box>
                    <QRShow />
                </Box>
            </Menu>
        </>
    );
}
