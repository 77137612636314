import { useEffect, useState } from 'react';
import { useRestaurantContext } from '@/contexts/restaurant';
import { Grid } from '@mui/material';
import { _exportQR } from '@/services/qr';

const QRShow = () => {
    const { restaurant } = useRestaurantContext();
    const url = 'https://vendor-demo.qlub.cloud/qba_links';
    const [qrSrc, setQrSrc] = useState<string>('');

    useEffect(() => {
        if (!restaurant) {
            return () => {
                //
            };
        }

        _exportQR(url, 'qr.png').then((file) => {
            setQrSrc(URL.createObjectURL(file));
        });

        return () => {
            URL.revokeObjectURL(qrSrc);
        };
    }, [restaurant]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <img src={qrSrc} alt={url} style={{ width: '200px', height: '200px' }} />
            </Grid>
        </Grid>
    );
};

export default QRShow;
