import { useTranslation } from '@/hooks/translations';
import { Button, Grid, TextField } from '@mui/material';
import { PrintRounded, VisibilityRounded } from '@mui/icons-material';
import { IRestaurantTables } from '@/views/OrdersTableView/types';
import React, { useState } from 'react';
import { IQR, QRModeType } from '@/views/QRCode/types';
import classNames from 'classnames';
import { useSpecialQr } from '@/views/QRCode/hooks';

import styles from './index.module.scss';

export interface IProps {
    qrList: IQR[] | IRestaurantTables[];
    loading: boolean;
    embed?: boolean;
    selectable?: boolean;
    selectedId?: string;
    onDynamicLink?: (item: IQR | IRestaurantTables, forcePrint?: boolean) => void;
    enableDynamicQr?: boolean;
    qrMode: QRModeType;
    onView?: (e: any) => void;
    onClick?: (item: IQR | IRestaurantTables) => void;
}

export default function QrGridView({
    qrList: preQrList,
    embed,
    selectable,
    selectedId,
    onDynamicLink,
    enableDynamicQr,
    qrMode,
    onView,
    onClick,
}: IProps) {
    const { t } = useTranslation('common');
    const [search, setSearch] = useState('');

    const qrList = useSpecialQr({ qrList: preQrList, qrMode });

    const printHandler = (item: IQR | IRestaurantTables) => () => {
        onDynamicLink?.(item, true);
    };

    return (
        <div
            className={classNames(styles.container, {
                [styles.embed]: embed,
                [styles.selectable]: selectable,
            })}
        >
            <div className={styles.search}>
                <TextField
                    fullWidth
                    placeholder={t('Search')}
                    size="small"
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
            </div>
            <div className={styles.box}>
                <Grid container spacing={1}>
                    {qrList.map((item) => {
                        if (
                            search &&
                            !`${item.params.id}|${item.params.name?.toLowerCase() || ''}`.includes(search.toLowerCase())
                        ) {
                            return null;
                        }
                        return (
                            <Grid item xs={embed ? 6 : 4} sm={embed ? 3 : 4} md={3} lg={embed ? 3 : 2}>
                                <div
                                    className={classNames(styles.item, {
                                        [styles.selected]: selectedId === item.id,
                                    })}
                                    onClick={() => {
                                        onClick?.(item);
                                    }}
                                >
                                    <div className={styles.itemHeader}>{item.params.id}</div>
                                    <div className={styles.itemSubheader}>{item.params.name}</div>
                                    <div className={styles.itemFooter}>
                                        {enableDynamicQr ? (
                                            <Button
                                                variant="text"
                                                endIcon={<PrintRounded />}
                                                onClick={printHandler(item)}
                                                // disabled={!printerActivate}
                                            >
                                                {t('Print')}
                                            </Button>
                                        ) : !selectable ? (
                                            <Button variant="text" endIcon={<VisibilityRounded />} onClick={onView}>
                                                {t('View')}
                                            </Button>
                                        ) : null}
                                    </div>
                                </div>
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        </div>
    );
}
