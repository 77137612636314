import QRCode from 'qrcode';
import { IRestaurant, IRestaurantTables } from '@/views/OrdersTableView/types';

export const _exportQR = (url: string, fileName: string): Promise<File> => {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas');
        canvas.height = 600;
        canvas.width = 600;
        QRCode.toCanvas(canvas, url, {
            width: 600,
            margin: 2,
            scale: 4,
            errorCorrectionLevel: 'medium',
        })
            .then(() => {
                return Promise.resolve();
            })
            .then(() => {
                return canvas.toBlob((data) => {
                    if (data) {
                        canvas.remove();
                        resolve(new File([data], fileName));
                    } else {
                        canvas.remove();
                        reject();
                    }
                }, 'image/png');
            })
            .catch((err) => {
                canvas.remove();
                reject(err);
            });
    });
};

export const exportQR = (qr: Partial<IRestaurantTables>, restaurant: IRestaurant): Promise<File> => {
    return _exportQR(
        qr.url || '',
        `${restaurant.country_code || ''}-${restaurant.restaurant_unique || ''}-${qr?.params?.id || '_'}-${
            qr?.params?.f1 || '_'
        }-${qr?.params?.f2 || '_'}.png`,
    );
};

export const downloadPaymentLinkQR = (url?: string, refId?: string): void => {
    _exportQR(url ?? '', `${refId}.png`).then((file: File) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(file);
        a.download = file.name;
        a.click();
    });
};
