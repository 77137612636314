import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';

interface IWhatsAppState {
    isOpen: boolean;
    defaultPhone?: string;
    defaultName?: string;
    defaultMsg?: string;
    defaultOrderId?: string;
}

export interface IWhatsAppContextValues extends IWhatsAppState {
    closeWhatsAppDrawer: () => void;
    openWhatsAppDrawer: (phone?: string, name?: string, defaultMsg?: string, defaultOrderId?: string) => void;
}

const WhatsAppContext = createContext<IWhatsAppContextValues | undefined>(undefined);

const initialState: IWhatsAppState = {
    isOpen: false,
    defaultPhone: undefined,
    defaultName: undefined,
    defaultMsg: undefined,
    defaultOrderId: undefined,
};

export function WhatsAppProvider({ children }: { children: React.ReactNode }) {
    const [state, setState] = useState<IWhatsAppState>(initialState);

    const openWhatsAppDrawer = useCallback(
        (phone?: string, name?: string, defaultMsg?: string, defaultOrderId?: string) => {
            setState((prev) => ({
                ...prev,
                isOpen: true,
                ...(phone && { defaultPhone: phone }),
                ...(name && { defaultName: name }),
                ...(defaultMsg && { defaultMsg }),
                ...(defaultOrderId && { defaultOrderId }),
            }));
        },
        [],
    );

    const closeWhatsAppDrawer = useCallback(() => {
        setState(initialState);
    }, []);

    const value = useMemo(
        () => ({
            ...state,
            closeWhatsAppDrawer,
            openWhatsAppDrawer,
        }),
        [state, closeWhatsAppDrawer, openWhatsAppDrawer],
    );

    return <WhatsAppContext.Provider value={value}>{children}</WhatsAppContext.Provider>;
}

export function useWhatsApp() {
    const context = useContext(WhatsAppContext);
    if (context === undefined) {
        throw new Error('useWhatsApp must be used within a WhatsAppProvider');
    }
    return context;
}
