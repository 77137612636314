import { useMemo } from 'react';
import { IQR, QRModeType } from '@/views/QRCode/types';
import { IRestaurantTables } from '@/views/OrdersTableView/types';

export const useSpecialQr = ({ qrList, qrMode }: { qrList: IQR[] | IRestaurantTables[]; qrMode: QRModeType }) => {
    return useMemo(() => {
        if (qrMode === 'normal') {
            return qrList;
        }

        return (
            qrList?.map((item) => {
                return {
                    ...item,
                    url: `${item.url}?qrMode=${qrMode}`,
                } as any;
            }) || []
        );
    }, [qrList, qrMode]);
};
